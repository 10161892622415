<template>
    <v-menu offset-y max-height="400" max-width="300" min-width="250" nudge-left v-model="menu">
      <template v-slot:activator="{ on: activated }">
        <span v-on="activated">
          <hb-btn
              type="icon"
              tooltip="Notifications"
              :active="menu"
            >
              <template slot-scope="{ hover, toggle }">
                <v-badge
                  :value="count"
                  color="#F26500"
                  overlap
                  dot
                  offset-x="4"
                >
                  <hb-icon
                    :hover="hover"
                    :toggle="toggle"
                  >
                    mdi-bell
                  </hb-icon>
                </v-badge>
              </template>
            </hb-btn>
          </span>
        </template>
<!--      <v-list subheader class="notifications">-->
<!--        <v-list-item-->
<!--          v-for="notify in notifications"-->
<!--          :key="notify.id"-->
<!--          flast-->
<!--          :class="{unread: !notify.status}"-->
<!--          @click.prevent="handleAlertClick(notify)"-->
<!--        >-->
<!--          <v-list-item-avatar>-->
<!--            <span class="activity-icon" :class="['action-' + notify.Activity.ActivityObject.name, 'status_' + notify.Activity.ActivityAction.name]"></span>-->
<!--          </v-list-item-avatar>-->
<!--          <v-list-item-content>-->
<!--            <span class="notify-date">{{notify.created | formatLocalDateTime}}</span><br />-->
<!--            <span v-html="$options.filters.nl2br(notify.Activity.message)"></span>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </v-list>-->

    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

  import api from '../../../assets/api.js';
export default {
    data() {
        return {
          menu: false,
        }
    },
    methods: {
        ...mapActions('notificationsStore', [
            'setNotifications'
        ]),
        handleAlertClick(notification){
            // Deprecated. .Type.name no longer exists
            switch(notification.Type.name){
                case "Event":
                    this.$router.push('/leases/' + notification.lease_id);
                    break;
                case "Message":
                    this.$router.push('/messages');
                    break;
                case "Maintenance Request":
                    this.$router.push('/leases/' + notification.lease_id + '/maintenance-requests');
                    break;
                case "Send Invoices":
                    this.$router.push('/dashboard');
                    break;
                case "Lead":
                    this.$router.push('/leads/' + notification.reference_id);
                    break;
            }
        }
    },
    computed: {
        ...mapGetters('notificationsStore', [
            'notifications'
        ]),
        count(){
            var n = this.notifications ? this.notifications.filter(n =>{ return !n.status  }): false;
            return n ? n.length: 0;
        }
    }
}
</script>
<style lang="scss">
</style>
