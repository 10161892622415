<template>
  <div>
    <div class="app-header-wrapper">
      <v-layout class="pb-2">
        <hb-breadcrumb @click="backToIntegrations()">
          Back to {{ parentScreen }}
        </hb-breadcrumb>
      </v-layout>

      <div class="app-header-details">
        <div class="app-icon-section">
          <span
            :class="appDetails.icon ? 'app-icon' : 'empty-app-icon'"
            :style="{ 'background-image': appDetails.icon ? `url('${appDetails.icon}')` : '' }"
          >
            <v-icon v-if="!appDetails.icon" size="40">
              mdi-image-broken-variant
            </v-icon>
          </span>
        </div>
        <div class="app-details-section pl-5">
          <span class="">
            <div class="hb-font-header-3-medium app-details">
              {{ appDetails.name.en }}
              <div
                class="hb-text-light hb-font-body font-weight-regular app-description"
              >
                {{ appDescription }}
              </div>
            </div>
          </span>
        </div>
        <div class="request-access-section d-flex justify-end align-center">
          <span
            class="d-flex align-center"
            :key="propertyStatus[applicationStatus].icon"
          >
            <hb-icon
              class="mt-1 mr-2"
              :color="propertyStatus[applicationStatus].color"
              >{{ propertyStatus[applicationStatus].icon }}</hb-icon
            >
            <span class="mt-1 hb-font-body-medium ml-n1">
              {{ propertyStatus[applicationStatus].label }}
            </span>
          </span>

          <hb-btn class="pl-4" color="primary" @click="handlePropertyAccessModal(true)">{{
            accessButtonText
          }}</hb-btn>
          <hb-btn v-show="hbSubscribedProperties?.length !=0" class="pl-4" color="primary" @click="handlePropertyUnsubscribeModal(true)">
            Unsubscribe</hb-btn>
        </div>
      </div>
      <v-divider elevation-5 class="divider-section"></v-divider>
    </div>

    <div class="app-container-details">
      <div class="inner-container">
        <media-carousel
          class="mt-2 mr-2"
          :media="appDetails.media"
        ></media-carousel>
        <div class="pr-2">
          <v-expansion-panels v-if="allProperties.filter(r => r.subscription_status != 'inactive').length" multiple v-model="propertyPanel">
            <hb-expansion-panel>
              <template v-slot:title> Property Status </template>
              <template v-slot:content>
                <HbTextField
                  class="pa-6"
                  search
                  width="350px"
                  v-model="searchedProperty"
                  placeholder="Search Properties"
                  style="margin-top: 0px !important;"
                />
                <div class="mb-6 mr-1 property-list-container">
                  <div v-if="propertySearchResults.length">
                    <div v-for="(property, index) in propertySearchResults" :key="index">
                      <div class="d-flex ma-6 mb-2 mt-0">
                        <ul class="list-items pl-4">
                          <li>{{ property.name }}</li>
                        </ul>

                        <div
                          class="property-status d-flex justify-end align-center"
                        >
                          <span class="d-flex align-center">
                            <hb-icon
                              small
                              :key="
                                propertyStatus[property.subscription_status]
                                  .icon
                              "
                              class="mr-2"
                              :color="
                                propertyStatus[property.subscription_status]
                                  .color
                              "
                              >{{
                                propertyStatus[property.subscription_status]
                                  .icon
                              }}</hb-icon
                            >
                            <span
                              class="mt-1 hb-font-caption-medium ml-n1"
                              :class="{
                                'hb-text-light':
                                  propertyStatus[property.subscription_status]
                                    .label === 'Inactive',
                              }"
                            >
                              {{
                                propertyStatus[property.subscription_status]
                                  .label
                              }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="pl-6 hb-text-light hb-font-body font-weight-regular"
                  >
                    No Results Found!
                  </div>
                </div>
              </template>
            </hb-expansion-panel>
          </v-expansion-panels>
          <hb-card no-title class="pa-6 mt-5">
            <div v-if="appDetails.description">
              <span v-html="appDetails.description"></span>
              <v-divider class="mt-5 mb-2"></v-divider>
            </div>

            <v-expansion-panels multiple class="pb-4">
              <hb-expansion-panel>
                <template v-slot:title> Permissions </template>
                <template v-slot:content>
                  <div v-if="appPermissions.length" class="pt-3 pb-3">
                    <ul
                      class="list-items ma-2"
                      v-for="(permission, index) in appPermissions"
                      :key="index"
                    >
                      <li>{{ permission }}</li>
                    </ul>
                  </div>
                  <div
                    class="pa-3 pl-4 hb-text-light hb-font-body font-weight-regular"
                    v-else
                  >
                    No Results Found!
                  </div>
                </template>
              </hb-expansion-panel>
              <hb-expansion-panel>
                <template v-slot:title> Settings </template>
                <template v-slot:content>
                  <div class="ma-4" v-if="appDetails.settings">
                    {{ appDetails.settings }}
                  </div>
                  <div
                    class="pa-3 pl-4 hb-text-light hb-font-body font-weight-regular"
                    v-else
                  >
                    No Results Found!
                  </div>
                </template>
              </hb-expansion-panel>

              <hb-expansion-panel>
                <template v-slot:title> Privacy Policy </template>
                <template v-slot:content>
                  <div class="ma-4" v-if="appDetails.privacy_policy">
                    <a target="_blank" :href="appDetails.privacy_policy">{{ appDetails.privacy_policy }}</a>
                  </div>
                  <div
                    class="pa-3 pl-4 hb-text-light hb-font-body font-weight-regular"
                    v-else
                  >
                    No Results Found!
                  </div>
                </template>
              </hb-expansion-panel>
            </v-expansion-panels>
            <v-divider class="mt-1 mb-3"></v-divider>
            <div>
              <span class="hb-font-header-3-medium">Disclaimer</span>
              <p class="pt-2 mb-0">
                You can go back and change permissions and settings at any time.
              </p>
            </div>
          </hb-card>
        </div>
        <hb-modal
          v-if="showPropertyRequestModal"
          v-model="showPropertyRequestModal"
          size="large"
          title="Property Status"
          @close="handlePropertyAccessModal(false)"
        >
          <template v-slot:content>
            <div v-if="hasSubscriptionError" class="mt-4 mx-6 mb-0">
              <hb-notification
                v-model="hasSubscriptionError"
                type="error"
              >
                The subscription request to the property has been failed! Please contact Tenant Customer Support.
              </hb-notification>
          </div>
            <div
              class="px-6 py-4 hb-text-light hb-font-body font-weight-regular"
            >
              Select the properties you would like to request access to
              {{ appDetails.name.en }}.
            </div>
            <div
              class="px-6 pb-4 hb-text-light hb-font-body font-weight-regular"
            >
              *Please note that ‘Active’ or ‘Pending’ properties are locked once
              requested. To change access, contact Tenant Customer Support at
              (949) 894-4500.
            </div>
            <v-divider class="divider"></v-divider>
            <div class="px-6">
              <HbTextField
                hide-details
                class="pt-5 pb-4"
                search
                width="300px"
                v-model="searchedPropertyInAccessScreen"
                placeholder="Search Properties"
              />

              <hb-checkbox
                v-if="accessScreenPropertySearchResults.length > 1"
                class="ma-0 pa-0 pb-3"
                hide-details
                v-model="selectAllProperties"
                label="Select All"
              />
              <div
                v-if="accessScreenPropertySearchResults.length"
                class="request-window-property-container"
              >
                <div
                  v-for="(property, index) in accessScreenPropertySearchResults"
                  :key="index"
                >
                  <v-layout>
                    <hb-checkbox
                      class="ma-0 pa-0 pb-4 property-checkbox"
                      hide-details
                      v-model="property.enabled"
                      :key="property.id"
                      :value="property.id"
                      :label="property.name"
                      :disabled="!property.eligible_to_subscribe"
                      @change="toggleRequestAccess()"
                    >
                    </hb-checkbox>

                    <div class="property-status pr-2 d-flex justify-end">
                      <span>
                        <hb-icon
                          small
                          class="mt-1 mr-2"
                          :color="
                            propertyStatus[property.subscription_status].color
                          "
                          >{{
                            propertyStatus[property.subscription_status].icon
                          }}</hb-icon
                        >
                        <span
                          class="mt-1 hb-font-caption-medium ml-n1"
                          :class="{
                            'hb-text-light':
                              propertyStatus[property.subscription_status]
                                .label === 'Inactive',
                          }"
                        >
                          {{
                            propertyStatus[property.subscription_status].label
                          }}
                        </span>
                      </span>
                    </div>
                  </v-layout>
                </div>
              </div>
              <div
                v-else
                class="pb-4 hb-text-light hb-font-body font-weight-regular"
              >
                No Results Found!
              </div>
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn color="primary" :disabled="!enableRequestAccess" @click="openPropertyAccessConfirmation()"
              >Request Access</hb-btn
            >
          </template>
        </hb-modal>

        <hb-modal
          v-if="showPropertyUnsubscribeModal"
          v-model="showPropertyUnsubscribeModal"
          size="large"
          title="Property Status"
          @close="handlePropertyAccessModal(false)"
        >
          <template v-slot:content>
            <div
              class="px-6 py-4 hb-text-light hb-font-body font-weight-regular"
            >
              Select the properties you would like to unsubscribe to
              {{ appDetails.name.en }}.
            </div>
            <v-divider class="divider"></v-divider>
            <div class="px-6">
              <HbTextField
                hide-details
                class="pt-5 pb-4"
                search
                width="300px"
                v-model="searchedPropertyInAccessScreen"
                placeholder="Search Properties"
              />

              <hb-checkbox
                v-if="accessScreenPropertySearchResults.length > 1"
                class="ma-0 pa-0 pb-3"
                hide-details
                v-model="selectAllUnsubscribeProperties"
                label="Select All"
              />
              <div
                v-if="accessScreenPropertySearchResults.length"
                class="request-window-property-container"
              >
                <div
                  v-for="(property, index) in accessScreenPropertySearchResults"
                  :key="index"
                >
                  <v-layout v-if="property.subscription_status == 'subscribed'">
                    <hb-checkbox
                      class="ma-0 pa-0 pb-4 property-checkbox"
                      hide-details
                      v-model="!property.enabled"
                      :key="property.id"
                      :value="property.id"
                      :label="property.name"
                      :disabled="false"
                      @change="toggleUnsubscribeAccess(property)"
                    >
                    </hb-checkbox>

                    <div class="property-status pr-2 d-flex justify-end">
                      <span>
                        <hb-icon
                          small
                          class="mr-2"
                          :color="
                            propertyStatus[property.subscription_status].color
                          "
                          >{{
                            propertyStatus[property.subscription_status].icon
                          }}</hb-icon
                        >
                        <span
                          class="mt-1 hb-font-caption-medium ml-n1"
                          :class="{
                            'hb-text-light':
                              propertyStatus[property.subscription_status]
                                .label === 'Inactive',
                          }"
                        >
                          {{
                            propertyStatus[property.subscription_status].label
                          }}
                        </span>
                      </span>
                    </div>
                  </v-layout>
                </div>
              </div>
              <div
                v-else
                class="pb-4 hb-text-light hb-font-body font-weight-regular"
              >
                No Results Found!
              </div>
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn v-if="!showPropertyUnsubscribeModal" color="primary" :disabled="!enableRequestAccess" @click="openPropertyAccessConfirmation()"
              >Request Access</hb-btn
            >
            <hb-btn v-else :disabled="unsubscribeProperties.length === 0" color="success" @click="unsubscribePropertyAccess()"
              >Unsubscribe</hb-btn
            >
            <loader
              v-if="showPropertyAccessLoader"
              color="#00b2ce"
              size="20px"
              class="ml-2 mt-2 inline-loader"
            ></loader>
          </template>
        </hb-modal>
        <hb-modal
          size="medium"
          title="Confirm"
          v-model="requestConfirmationDialog"
          confirmation
        >
          <template v-slot:content>
            <div class="py-4 px-6">
              {{ appDetails.name.en }} requires you to ...
              <div class="pt-3 pb-3">
                <template>
                  <ul
                    class="list-items ma-2"
                    v-for="(permission, index) in appPermissions"
                    :key="index"
                  >
                    <li>{{ permission }}</li>
                  </ul>
                </template>
                <!-- <template v-else>
                  <ul
                    class="list-items ma-2"
                  >
                    <li>0 Permissions</li>
                  </ul>
                </template> -->
              </div>
              Do you want to authorize these permissions?
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn v-if="showPropertyUnsubscribeModal" color="success" @click="unsubscribePropertyAccess()"
              >Disable</hb-btn
            >
            <hb-btn v-else color="success" @click="requestPropertyAccess()"
              >Enable</hb-btn
            >
            <loader
              v-if="showPropertyAccessLoader"
              color="#00b2ce"
              size="20px"
              class="ml-2 mt-2 inline-loader"
            ></loader>
          </template>
        </hb-modal>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../assets/api";
import Loader from "../../assets/CircleSpinner.vue";
import { getPassthroughRequestDetails } from "../../../../src/utils/common"
import MediaCarousel from "../app_subscription/AppDetailsMediaCarousel.vue";
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import Vue from "vue";

export default {
  name: "AppDetailsScreen",
  components: {
    MediaCarousel,
    Loader,
  },
  props: {
    appDetails: {
      type: Object,
      required: false,
      default: () => ({
        name: {
          en: ""
        },
        icon: "",
        properties: [],
        caption: ""
      }),
    },
    parentScreen: {
      type: String,
      required: false,
      default: "Discover",
    },
    hbSubscribedProperties: {
      type: Array,
      required: false,
      default: () => [],
    },
    applicationStatus: {
      type: String,
      required: false,
      default: "inactive",
    },
  },
  data() {
    return {
      propertyPanel: [0],
      searchedProperty: "",
      permissions: [],
      showPropertyRequestModal: false,
      showPropertyUnsubscribeModal: false,
      selectAllProperties: false,
      selectAllUnsubscribeProperties: false,
      searchedPropertyInAccessScreen: "",
      selectedPropertyUnsubscribe: false,
      requestConfirmationDialog: false,
      showPropertyAccessLoader: false,
      unsubscribeProperties: [],
      propertyStatus: {
        subscribed: {
          icon: "mdi-check-circle",
          color: "success",
          label: "Active",
        },
        rejected: {
          icon: "mdi-minus-circle",
          color: "error",
          label: "Rejected",
        },
        pending: {
          icon: "mdi-alert-octagon",
          color: "caution",
          label: "Pending",
        },
        inactive: {
          icon: "mdi-minus-circle",
          color: "",
          label: "Inactive",
        },
      },
      hasSubscriptionError: false,
      enableRequestAccess: false,
      forceCompute:0,
      newSubscribed:[],
      newUnsubscribed:[]
    };
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/properties",
      developerPortalAppId: "authenticationStore/getDeveloperPortalAppId",
    }),
    accessButtonText() {
      return this.hbSubscribedProperties?.length
        ? "+ Add Properties"
        : "Request Access";
    },
    allProperties() {
      this.forceCompute
      // All the properties that the user has access to
      let properties = JSON.parse(JSON.stringify(this.properties));
            // All the properties that are subscribed to the selected app
      let subscribedPropertyIds = Object.values(this.hbSubscribedProperties ?? [])?.map(subscribedProperty => subscribedProperty.id);
      subscribedPropertyIds = [...subscribedPropertyIds, ...this.newSubscribed]
      this.newSubscribed  = []
      subscribedPropertyIds = subscribedPropertyIds.filter(item => !this.newUnsubscribed.includes(item)).map(item => {return item})
      this.newUnsubscribed = []
      // Update the default property and status and find the subscribed properties
      for (let idx in properties) {
        let propertyDetails = properties[idx]
        propertyDetails.enabled = false;
        propertyDetails.subscription_status = "inactive";
        propertyDetails.eligible_to_subscribe = true;

        if (subscribedPropertyIds.includes(propertyDetails.id)) {
          propertyDetails.subscription_status = "subscribed";
          propertyDetails.eligible_to_subscribe = false;
          propertyDetails.enabled = true;
        }
      }

      // Update the property status of 'pending' and 'rejected'
      for (let prop in this.appDetails.properties) {
        let appProperty = this.appDetails.properties[prop];
        let property = properties.find(p => p.id === appProperty.id);

        if (property && property.subscription_status !== "subscribed") {
          let appStatus = appProperty.status.toLowerCase();

          if (["pending", "rejected"].includes(appStatus)) {
            property.subscription_status = appStatus;
            property.eligible_to_subscribe = false;
            property.enabled = true;
          }
        }
      }
      return properties;
    },


    appDescription() {
      let version = this.getDefault(this.appDetails?.version);
      let created = this.getDefault(this.convertTimeStamp(this.appDetails?.created_on));
      let updated = this.getDefault(this.convertTimeStamp(this.appDetails?.updated_on));
      let supportName = this.getDefault(this.appDetails?.support?.name || "Tenant Inc");
      let supportEmail = this.getDefault(this.appDetails?.support?.email || "support@tenantinc.com");
      return `Version: ${version} | Created: ${created} | Updated: ${updated} | ${supportName} | ${supportEmail}`;

    },
    propertySearchResults() {
      return this.filterProperties(this.searchedProperty)
    },
    propertySearchResultsSize() {
      let allProperties = this.filterProperties(this.searchedProperty)
      let activeProperties = allProperties.filter(r => r.subscription_status != 'inactive')
      return activeProperties
    },
    accessScreenPropertySearchResults() {
      return this.filterProperties(this.searchedPropertyInAccessScreen)
    },
    appPermissions() {
      let namesFromPermissions = [];
      for (let permission of this.appDetails?.hb_permissions || []) {
        let matchedPermission = this.permissions.find(obj => obj.label === permission);
        if (matchedPermission) {
          namesFromPermissions.push(matchedPermission.name);
        }
      }
      return namesFromPermissions;
    },
  },
  watch: {
    async selectAllProperties(val) {
      let isEnable = !!val;
      for (let property of this.allProperties) {
        if (property.subscription_status === "inactive") {
          property.enabled = isEnable;
        }
      }
      let selectedProperties = await this.getSelectedProperties()
      this.enableRequestAccess = !!selectedProperties.length;
    },
    async selectAllUnsubscribeProperties(val) {
      let isEnable = !val;
      
      for (let property of this.allProperties) {
        if (property.subscription_status === "subscribed") {
          property.enabled = isEnable;
          this.unsubscribeProperties.push({
            name: property?.name,
            id: property?.id,
            gds_id: property?.gds_id,
            address: this.joinUnsubscribeAddress(property?.Address)
          });
        }
      }
      if(isEnable == true){
        this.unsubscribeProperties = [];
        return
      }
      let selectedProperties = await this.getSelectedProperties()
      this.enableRequestAccess = !!selectedProperties.length;
    }
  },
  async created() {
    await this.fetchPermissions();
  },

  methods: {
    ...mapActions({
      setNectarApplications: "authenticationStore/setNectarApplications",
    }),
    
    async getSelectedProperties() {
      let selectedProperties = []
      for (let property of this.allProperties) {
        if (property.subscription_status === "inactive" && property.enabled) {
          selectedProperties.push({
            name: property?.name,
            id: property?.id,
            gds_id: property?.gds_id,
            address: await this.joinAddress(property?.Address),
          });
        }
      }
      return selectedProperties;
    },

    filterProperties(searchedProperty) {
      if (searchedProperty) {
        let searchedItems = this.allProperties?.filter((property) =>
          property.name
            ?.toLowerCase()
            ?.includes(searchedProperty?.toLowerCase())
        );

        return searchedItems;
      } else return this.allProperties;
    },

    async toggleRequestAccess() {
      let selectedProperties = await this.getSelectedProperties()
      this.enableRequestAccess = !!selectedProperties.length;
    },
    
    async toggleUnsubscribeAccess(property) {
      let indexToRemove = this.unsubscribeProperties.findIndex(obj => obj.id == property.id);
      if(indexToRemove != -1){
        this.unsubscribeProperties.splice(indexToRemove, 1);
      }else {
        this.unsubscribeProperties.push({
            name: property?.name,
            id: property?.id,
            gds_id: property?.gds_id,
            address: await this.joinAddress(property?.Address)
          });
      }
    },
    backToIntegrations() {
      this.$emit("loadApplications");
    },
    getDefault(data, defaultValue = "N/A") {
      return !data ? defaultValue : data;
    },

    async fetchPermissions() {
      let response = await api.get(this, api.SETTINGS + "permissions");
      this.permissions = response.permissions;
    },
    convertTimeStamp(timestamp) {
      let formattedDate = null;
      let timeStampInMilliSeconds = timestamp * 1000;
      if(!isNaN(timeStampInMilliSeconds)) {
        let date = new Date(timeStampInMilliSeconds);
        formattedDate = moment(date).format('ll'); //Eg: Oct 30, 2023
      }
      return formattedDate;
    },
    handlePropertyAccessModal(isOpen) {
      this.showPropertyRequestModal = isOpen;
      this.unsubscribeProperties = [];
      this.resetPropertyAccessModal();
    },
    handlePropertyUnsubscribeModal(isOpen) {
      this.showPropertyUnsubscribeModal = isOpen;
      this.resetPropertyAccessModal();
    },
    openPropertyAccessConfirmation() {
      this.requestConfirmationDialog = true;
    },

    resetPropertyAccessModal() {
      this.selectAllProperties = false;
      this.selectAllUnsubscribeProperties = false;
      this.searchedPropertyInAccessScreen = "";
      this.hasSubscriptionError = false;
      for (let property of this.allProperties) {
        if (property.subscription_status === "inactive" && property.enabled) {
          property.enabled = false
        }
      }
    },
    async joinAddress(address) {
      let addressParts = [
        address?.address,
        address?.address2,
        address?.city,
        address?.country,
      ];
      return addressParts.filter(Boolean).join(", ");
    },
    joinUnsubscribeAddress(address) {
      let addressParts = [
        address?.address,
        address?.address2,
        address?.city,
        address?.country,
      ];
      return addressParts.filter(Boolean).join(", ");
    },
    async requestPropertyAccess() {
      this.showPropertyAccessLoader = true;
      let selectedProperties = await this.getSelectedProperties();
      let request = getPassthroughRequestDetails(
        this.developerPortalAppId, 
        api.PASSTHROUGH_DEVPORTAL_ENDPOINT
      )
      let userDetails = JSON.parse(localStorage.getItem("hb_user_details"))
      const { contact } = userDetails;
      let contactDetails = {
        "first" : contact.first ,
        "last" : contact.last , 
        "email" : contact.email
      }
      let requestBody = {
        properties: selectedProperties,
        contactDetails
      };

      try {
        await Vue.http.post(
          `${request.uri}${this.appDetails.app_id}/subscriptions/`,
          requestBody,
          { headers: request.headers }
        );
        await this.setNectarApplications();
        selectedProperties.map(item => {
          this.newSubscribed.push(item.id)
        })
          this.forceCompute++
        this.showPropertyRequestModal = false;    
      } catch (e) {
        console.log(e);
        this.hasSubscriptionError = true;
      }
      this.showPropertyAccessLoader = false;
      this.requestConfirmationDialog = false;
    },

    async unsubscribePropertyAccess(){
      this.showPropertyAccessLoader = true;
      let selectedProperties = this.unsubscribeProperties;
      let request = getPassthroughRequestDetails(
        this.developerPortalAppId, 
        api.PASSTHROUGH_DEVPORTAL_ENDPOINT
      )
      let userDetails = JSON.parse(localStorage.getItem("hb_user_details"))
      const { contact } = userDetails;
      let contactDetails = {
        "first" : contact.first ,
        "last" : contact.last , 
        "email" : contact.email
      }
      let requestBody = {
        properties: selectedProperties,
        contactDetails
      };

      try {
        await Vue.http.post(
          `${request.uri}${this.appDetails.app_id}/unsubscribe/`,
          requestBody,
          { headers: request.headers }
        );
        this.requestConfirmationDialog = false;  
        this.unsubscribeProperties = [];
        await this.setNectarApplications();
        selectedProperties.map(item => {
          this.newUnsubscribed.push(item.id)
        })
        this.forceCompute++
        this.showPropertyUnsubscribeModal = false;  
      } catch (e) {
        console.log(e);
        this.hasSubscriptionError = true;
        this.showPropertyUnsubscribeModal = false;  
        this.unsubscribeProperties = [];
      }
      this.showPropertyAccessLoader = false;
      this.requestConfirmationDialog = false; 
    }
  },
};
</script>

<style lang="scss" scoped>
.app-header-wrapper {
  background-color: #f9fafb;
  padding: 40px 40px 0 28px;
  position: fixed;
  width: 100%;
  z-index: 2;
  margin-top: 50px;
}
.property-checkbox {
  width: 80%;
}
.app-container-details {
  padding: 220px 28px 0px 28px;
}

.inner-container {
  padding-bottom: 50px;
  max-height: calc(100vh - 220px);
  overflow: auto;
}
.app-icon {
  display: block;
  width: 63px;
  height: 63px;
  border-radius: 6px;
  padding: 10px;
  background-size: contain;
}

.empty-app-icon {
  display: block;
  width: 63px;
  height: 63px;
  border-radius: 6px;
  border: 1px solid #dfe3e8;
  padding: 10px;
  background-size: contain;
}
.app-details {
  font-size: 24px;
}
.app-description {
  font-size: 14px;
  padding-top: 2px;
}
.app-header-details {
  display: flex;
  padding: 20px 0;
}
.app-icon-section,
.app-details-section,
.request-access-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.request-access-section {
  flex: 1;
  text-align: right;
}

.list-items {
  list-style-type: disc !important;
}
.property-status {
  margin-left: 10px;
  display: flex;
}
.property-list-container {
  max-height: 290px;
  overflow: auto;
}
.request-window-property-container {
  max-height: 400px;
  overflow: auto;
  padding-bottom: 20px;
}
.property-list-container::-webkit-scrollbar,
.request-window-property-container::-webkit-scrollbar,
.inner-container::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}
.property-list-container::-webkit-scrollbar-thumb,
.inner-container::-webkit-scrollbar-thumb,
.request-window-property-container::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);
  cursor: pointer;
}
</style>
