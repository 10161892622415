<template>
  <div class="ma-2 mr-8">
    <div
      v-if="appDetails"
      @click="openApp(appDetails)"
      class="app-detail-wrapper"
    >
      <v-row no-gutter class="app-detail-container pa-3 pl-2">
        <v-col cols="1" md="2" sm="3" lg="2" xl="1" class="mr-1">
          <span 
          :class="appDetails.icon ? 'app-icon' : 'empty-app-icon'"
          :style="{ 'background-image': appDetails.icon ? `url('${appDetails.icon}')` : '' }">
            <v-icon 
              v-if="!appDetails.icon" 
              size="40">
                mdi-image-broken-variant
            </v-icon>
          </span>

        </v-col>
        <v-col
          cols="9"
          md="8"
          sm="6"
          lg="9"
          xl="9"
          class="d-flex align-center ml-6 mb-0 pb-0"
        >
          <span>
            <span class="d-flex align-center">
              <span class="mt-0 mr-1 hb-font-header-3-medium app-name-section">
                {{ appDetails.name.en }}
              </span>

              <hb-tooltip v-if="isSubscribed">
                <template v-slot:item>
                  <hb-icon small class="mt-1 mr-2" color="success"
                    >mdi-check-circle</hb-icon
                  >
                </template>
                <template v-slot:header> Active </template>
                <template v-slot:body>
                  <span>
                    {{ getPropertyCountDetails(subscribedProperties) }}
                  </span>
                </template>
              </hb-tooltip>

              <hb-tooltip v-if="!isSubscribed && isPending">
                <template v-slot:item>
                  <hb-icon small class="mt-1 mr-2" color="caution"
                    >mdi-alert-octagon</hb-icon
                  >
                </template>
                <template v-slot:header> Pending </template>
                <template v-slot:body>
                  <span v-if="appDetails.properties">
                    {{ getPropertyCountDetails(appDetails.properties) }}
                  </span>
                </template>
              </hb-tooltip>
            </span>

            <div>
              <p
                class="app-description-section hb-text-light hb-font-body font-weight-regular"
              >
                {{ appDetails.caption ?? '' }}
              </p>
            </div>
          </span>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-skeleton-loader
        class="app-detail-container"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppListItem",
  props: {
    appDetails: {
      type: Object,
      required: false,
      default: () => ({
        name: {
          en: ""
        },
        icon: "",
        properties: [],
        caption: ""
      }),
    },
    isSubscribed: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPending: {
      type: Boolean,
      required: false,
      default: false,
    },
    subscribedProperties: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  methods: {
    openApp(app) {
      this.$emit("openApp", app);
    },
    getPropertyCountDetails(properties) {
      let text = properties?.length == 1 ? 'Property' : 'Properties';
      return `${properties?.length} ${text}`
    }
  },
};
</script>
<style lang="scss" scoped>
.app-detail-wrapper {
  cursor: pointer;
}
.app-detail-container {
  border: 1px solid #dfe3e8;
  background-color: white;
  border-radius: 6px;
  min-height: 115px;
  &:hover {
    background-color: #f0f0f0;
  }
}

.app-icon {
  display: block;
  width: 63px;
  height: 63px;
  border-radius: 12px;
  padding: 10px;
  background-size: contain;
}
.empty-app-icon {
  display: block;
  width: 63px;
  height: 63px;
  border-radius: 12px;
  border: 1px solid #dfe3e8;
  padding: 10px;
  background-size: contain;
}
.app-name-section {
  display: flex;
  align-items: center;
}
.app-description-section {
  font-size: 12px;
}
</style>
